<template>
  <b-card
    no-body
    :aria-expanded="!content_visible ? 'true' : 'false'"
    :aria-controls="parentID"
  >
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>
          <feather-icon :icon="headerIcon" size="20" />
          {{ headerTitle }}
        </b-card-title>
        <b-card-sub-title>
          {{ headerSubTitle }}
        </b-card-sub-title>
      </div>

      <!-- Card Actions -->
      <div class="heading-elements">
        <ul class="list-inline mb-0">

          <li>
              <!--
            <b-link class="card-link" style="font-weight: 500">
              Gestionar
            </b-link>
            -->
          </li>
          <li>
            <a
              @click="content_visible = !content_visible"
              :class="{ rotate: !content_visible }"
              data-action="collapse"
            >
              <feather-icon icon="ChevronDownIcon" size="14" />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <b-collapse
      v-model="content_visible"
      :visible="content_visible"
      :id="parentID"
      class="card-content"
    >
      <b-card-body>
        <div
          class="d-flex justify-content-between"
          style="margin-top: 3.4rem; margin-bottom: 1.2rem"
          v-if="statisticsType == 'text'"
        >
          <div
            class="text-center"
            v-for="(item, index) in statistics"
            :key="index"
          >
            <div class="font-small-2">{{ item.metricsDescription }}</div>
            <span class="font-large-1 font-weight-bold">{{
              item.metricsValue
            }}</span>
          </div>
        </div>

        <div
          class="d-flex justify-content-between"
          style="margin-top: 3.4rem; margin-bottom: 1.2rem"
          v-if="statisticsType == 'icon'"
        >
          <b-media no-body v-for="(item, index) in statistics" :key="index">
            <b-media-aside class="mr-2" v-if="item.icon">
              <b-avatar size="48" :variant="item.variant">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ item.metricsValue }}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.metricsDescription }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </div>

        <div style="margin-top: 3.7rem">
          <ul class="list-unstyled">
            <li
              style="margin-top: 1.2rem"
              v-for="(item, index) in shortcuts"
              :key="index"
            >
              <b-link
                :href="item.href"
                :to="item.to"
                class="card-link"
                style="font-weight: 500"
              >
                {{ item.text }}
              </b-link>
            </li>
          </ul>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import axios from '@axios'

import
{
  BFormDatepicker, BButton, BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  BLink,
  VBPopover,
  BSpinner,
  BOverlay,
  BCollapse
} from "bootstrap-vue";

export default {

  directives: {
  },

  props: {

    dataUrl: {
      type: String,
      required: false,
    },


  },

  data()
  {



    return {

      parentID: '',
      content_visible: true,

      headerIcon: "",
      headerTitle: "",
      headerSubTitle: "",

      statisticsType: 'text',

      statistics: [],

      shortcuts: []


    };
  },


  components: {
    BButton,
    BRow,
    BCol,
    BFormDatepicker,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BLink,
    BCollapse
  },

  created()
  {
    this.parentID = String(Math.floor(Math.random() * 10) + 1)

    this.getData()
      .then((response) =>
      {

        this.headerIcon = response.data.headerIcon
        this.headerTitle = response.data.headerTitle
        this.headerSubTitle = response.data.headerSubTitle

        this.statisticsType = response.data.statisticsType

        this.statistics = response.data.statistics

        this.shortcuts = response.data.shortcuts


      })




  },


  methods: {


    getData()
    {
      return axios.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      })
    },



  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
